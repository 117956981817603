export const FETCH_CURRENT_USER_STARTED = "fetch_current_user_started";
export const FETCH_CURRENT_USER_SUCCESS = "fetch_current_user_success";
export const FETCH_CURRENT_USER_FAILURE = "fetch_current_user_failure";

export const LOGIN_USER_STARTED = "login_user_started";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAILURE = "login_user_failure";

export const SIGNUP_USER_STARTED = "signup_user_started";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNUP_USER_FAILURE = "signup_user_failure";

export const LOGOUT_USER = "logout_user";

export const CLEAR_AUTH_STATE = "clear_auth_state";

export const FETCH_KEYWORDS_STARTED = "fetch_keywords_started";
export const FETCH_KEYWORDS_SUCCESS = "fetch_keywords_success";
export const FETCH_KEYWORDS_FAILURE = "fetch_keywords_failure";

export const CREATE_KEYWORDS_STARTED = "create_keywords_started";
export const CREATE_KEYWORDS_SUCCESS = "create_keywords_success";
export const CREATE_KEYWORDS_FAILURE = "create_keywords_failure";

export const DELETE_KEYWORDS_STARTED = "delete_keywords_started";
export const DELETE_KEYWORDS_SUCCESS = "delete_keywords_success";
export const DELETE_KEYWORDS_FAILURE = "delete_keywords_failure";

export const UPDATE_KEYWORDS_STARTED = "update_keywords_started";
export const UPDATE_KEYWORDS_SUCCESS = "update_keywords_success";
export const UPDATE_KEYWORDS_FAILURE = "update_keywords_failure";

export const FETCH_SUBSCRIPTION_STARTED = "fetch_subscription_started";
export const FETCH_SUBSCRIPTION_SUCCESS = "fetch_subscription_success";
export const FETCH_SUBSCRIPTION_FAILURE = "fetch_subscription_failure";

export const CREATE_SUBSCRIPTION_STARTED = "create_subscription_started";
export const CREATE_SUBSCRIPTION_SUCCESS = "create_subscription_success";
export const CREATE_SUBSCRIPTION_FAILURE = "create_subscription_failure";

export const UPDATE_SUBSCRIPTION_STARTED = "update_subscription_started";
export const UPDATE_SUBSCRIPTION_SUCCESS = "update_subscription_success";
export const UPDATE_SUBSCRIPTION_FAILURE = "update_subscription_failure";

export const DELETE_SUBSCRIPTION_STARTED = "delete_subscription_started";
export const DELETE_SUBSCRIPTION_SUCCESS = "delete_subscription_success";
export const DELETE_SUBSCRIPTION_FAILURE = "delete_subscription_failure";

export const UPDATE_PAYMENT_SOURCE_STARTED = "update_paymemt_source_started";
export const UPDATE_PAYMENT_SOURCE_SUCCESS = "update_paymemt_source_success";
export const UPDATE_PAYMENT_SOURCE_FAILURE = "update_paymemt_source_failure";

export const DELETE_PAYMENT_SOURCE_STARTED = "delete_paymemt_source_started";
export const DELETE_PAYMENT_SOURCE_SUCCESS = "delete_paymemt_source_success";
export const DELETE_PAYMENT_SOURCE_FAILURE = "delete_paymemt_source_failure";

export const FETCH_PLANS_STARTED = "fetch_plans_started";
export const FETCH_PLANS_SUCCESS = "fetch_plans_success";
export const FETCH_PLANS_FAILURE = "fetch_plans_failure";

export const FETCH_INVOICE_STARTED = "fetch_invoice_started";
export const FETCH_INVOICE_SUCCESS = "fetch_invoice_success";
export const FETCH_INVOICE_FAILURE = "fetch_invoice_failure";

export const FETCH_ACCOUNT_STARTED = "fetch_account_started";
export const FETCH_ACCOUNT_SUCCESS = "fetch_account_success";
export const FETCH_ACCOUNT_FAILURE = "fetch_account_failure";

export const FETCH_TEAM_MEMBERS_STARTED = "fetch_team_members_started";
export const FETCH_TEAM_MEMBERS_SUCCESS = "fetch_team_members_success";
export const FETCH_TEAM_MEMBERS_FAILURE = "fetch_team_members_failure";

export const CREATE_INVITE_STARTED = "create_invite_started";
export const CREATE_INVITE_SUCCESS = "create_invite_success";
export const CREATE_INVITE_FAILURE = "create_invite_failure";

export const FETCH_INVITE_STARTED = "fetch_invite_started";
export const FETCH_INVITE_SUCCESS = "fetch_invite_success";
export const FETCH_INVITE_FAILURE = "fetch_invite_failure";

export const CREATE_TEAM_MEMBER_STARTED = "create_team_member_started";
export const CREATE_TEAM_MEMBER_SUCCESS = "create_team_member_success";
export const CREATE_TEAM_MEMBER_FAILURE = "create_team_member_failure";

export const DELETE_TEAM_MEMBER_STARTED = "delete_team_member_started";
export const DELETE_TEAM_MEMBER_SUCCESS = "delete_team_member_success";
export const DELETE_TEAM_MEMBER_FAILURE = "delete_team_member_failure";

export const UPDATE_TEAM_MEMBER_STARTED = "update_team_member_started";
export const UPDATE_TEAM_MEMBER_SUCCESS = "update_team_member_success";
export const UPDATE_TEAM_MEMBER_FAILURE = "update_team_member_failure";

export const CREATE_ACCOUNT_STARTED = "create_account_started";
export const CREATE_ACCOUNT_SUCCESS = "create_account_success";
export const CREATE_ACCOUNT_FAILURE = "create_account_failure";

export const FETCH_OPEN_MESSAGES_STARTED = "fetch_open_messages_started";
export const FETCH_OPEN_MESSAGES_SUCCESS = "fetch_open_messages_success";
export const FETCH_OPEN_MESSAGES_FAILURE = "fetch_open_messages_failure";

export const FETCH_CLOSED_MESSAGES_STARTED = "fetch_closed_messages_started";
export const FETCH_CLOSED_MESSAGES_SUCCESS = "fetch_closed_messages_success";
export const FETCH_CLOSED_MESSAGES_FAILURE = "fetch_closed_messages_failure";

export const FETCH_MY_MESSAGES_STARTED = "fetch_my_messages_started";
export const FETCH_MY_MESSAGES_SUCCESS = "fetch_my_messages_success";
export const FETCH_MY_MESSAGES_FAILURE = "fetch_my_messages_failure";

export const FETCH_SPAM_MESSAGES_STARTED = "fetch_spam_messages_started";
export const FETCH_SPAM_MESSAGES_SUCCESS = "fetch_spam_messages_success";
export const FETCH_SPAM_MESSAGES_FAILURE = "fetch_spam_messages_failure";

export const UPDATE_MESSAGE_STARTED = "update_message_started";
export const UPDATE_MESSAGE_SUCCESS = "update_message_success";
export const UPDATE_MESSAGE_FAILURE = "update_message_failure";

export const FETCH_CONVERSATION_STARTED = "fetch_conversation_started";
export const FETCH_CONVERSATION_SUCCESS = "fetch_conversation_success";
export const FETCH_CONVERSATION_FAILURE = "fetch_conversation_failure";

export const CREATE_REDDIT_CONVERSATION_REPLY_STARTED =
  "create_reddit_conversation_reply_started";
export const CREATE_REDDIT_CONVERSATION_REPLY_SUCCESS =
  "create_reddit_conversation_reply_success";
export const CREATE_REDDIT_CONVERSATION_REPLY_FAILURE =
  "create_reddit_conversation_reply_failure";

export const UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_STARTED =
  "update_team_member_social_account_access_started";
export const UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_SUCCESS =
  "update_team_member_social_account_access_success";
export const UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_FAILURE =
  "update_team_member_social_account_access_failure";

export const DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_STARTED =
  "delete_team_member_social_account_access_started";
export const DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_SUCCESS =
  "delete_team_member_social_account_access_success";
export const DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_FAILURE =
  "delete_team_member_social_account_access_failure";

export const FETCH_MORE_REDDIT_COMMENTS_STARTED =
  "fetch_more_reddit_comments_started";
export const FETCH_MORE_REDDIT_COMMENTS_SUCCESS =
  "fetch_more_reddit_comments_success";
export const FETCH_MORE_REDDIT_COMMENTS_FAILURE =
  "fetch_more_reddit_comments_failure";

export const FETCH_FACEBOOK_PAGES_STARTED = "fetch_facebook_pages_started";
export const FETCH_FACEBOOK_PAGES_SUCCESS = "fetch_facebook_pages_success";
export const FETCH_FACEBOOK_PAGES_FAILURE = "fetch_facebook_pages_failure";

export const FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_STARTED =
  "fetch_authorized_social_accounts_started";
export const FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_SUCCESS =
  "fetch_authorized_social_accounts_success";
export const FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_FAILURE =
  "fetch_authorized_social_accounts_failure";

export const FETCH_SCHEDULED_POSTS_STARTED = "fetch_scheduled_posts_started";
export const FETCH_SCHEDULED_POSTS_SUCCESS = "fetch_scheduled_posts_success";
export const FETCH_SCHEDULED_POSTS_FAILURE = "fetch_scheduled_posts_failure";

export const CREATE_SCHEDULED_POSTS_STARTED = "create_scheduled_posts_started";
export const CREATE_SCHEDULED_POSTS_SUCCESS = "create_scheduled_posts_success";
export const CREATE_SCHEDULED_POSTS_FAILURE = "create_scheduled_posts_failure";

export const DELETE_SCHEDULED_POSTS_STARTED = "delete_scheduled_posts_started";
export const DELETE_SCHEDULED_POSTS_SUCCESS = "delete_scheduled_posts_success";
export const DELETE_SCHEDULED_POSTS_FAILURE = "delete_scheduled_posts_failure";

export const UPDATE_SCHEDULED_POSTS_STARTED = "update_scheduled_posts_started";
export const UPDATE_SCHEDULED_POSTS_SUCCESS = "update_scheduled_posts_success";
export const UPDATE_SCHEDULED_POSTS_FAILURE = "update_scheduled_posts_failure";

export const DELETE_SLACK_INTEGRATION_STARTED =
  "delete_slack_integration_started";
export const DELETE_SLACK_INTEGRATION_SUCCESS =
  "delete_slack_integration_success";
export const DELETE_SLACK_INTEGRATION_FAILURE =
  "delete_slack_integration_failure";