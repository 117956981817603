import React, { Component } from "react";
import {
  Row,
  Col,
  Icon,
  Table,
  Divider,
  Card,
  Input,
  Dropdown,
  Menu,
  Button,
  Popconfirm
} from "antd";
import format from "date-fns/format";
import PageHeader from "ant-design-pro/lib/PageHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { fetchKeywords, deleteKeyword } from "../../actions";
import AddKeyword from "./AddKeyword";
import EditKeyword from "./EditKeyword";
const { Search } = Input;

class Keywords extends Component {
  state = {
    sort: "Select..",
    keywordData: [],
    searchValue: ""
  };

  componentDidMount() {
    this.props.fetchKeywords();
  }

  componentDidUpdate(prevProps) {
    if (this.props.keywords !== prevProps.keywords) {
      let data = this.props.keywords.data;
      this.setState({ keywordData: data });
    }
  }

  handleSort = e => {
    let data = [];
    switch (e.key) {
      case "Oldest":
        data = this.props.keywords.data
          .filter(
            item =>
              item.keyword.search(new RegExp(this.state.searchValue, "i")) !==
              -1
          )
          .sort(function(a, b) {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
        return this.setState({ sort: e.key, keywordData: data });

      case "Latest":
        data = this.props.keywords.data
          .filter(
            item =>
              item.keyword.search(new RegExp(this.state.searchValue, "i")) !==
              -1
          )
          .sort(function(a, b) {
            return (
              (new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()) *
              -1
            );
          });
        return this.setState({ sort: e.key, keywordData: data });
      default:
        data = [];
    }
  };

  dropdownMenu = (
    <Menu onClick={this.handleSort}>
      <Menu.Item key="Oldest">Oldest</Menu.Item>
      <Menu.Item key="Latest">Latest</Menu.Item>
    </Menu>
  );
  render() {
    const { sort, keywordData } = this.state;

    const columns = [
      {
        title: "Keyword",
        key: "keyword",
        render: (text, record) => (
          <div>
            <div style={{ marginBottom: "5px" }}>
              <span>Created: {format(record.createdAt, "MMM D, YYYY")}</span>
            </div>
            <div>
              <h2>{record.keyword}</h2>
            </div>
          </div>
        )
      },
      {
        title: "Match Whole Word Only",
        key: "wholeWordOnly",
        render: (text, record) => (
          <div>
            <Icon
              type={record.wholeWordOnly ? "check-square" : "close-square"}
              style={
                record.wholeWordOnly
                  ? { color: "blue", fontSize: "20px" }
                  : { color: "red", fontSize: "20px" }
              }
            />
          </div>
        )
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          console.log({ record });
          return (
            <span>
              <EditKeyword record={record} />
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.props.deleteKeyword(record._id)}
              >
                <Button>Delete</Button>
              </Popconfirm>
            </span>
          );
        }
      }
    ];
    return (
      <React.Fragment>
        <div style={{ margin: "-24px -24px 0" }}>
          <PageHeader
            key="pageheader"
            title="Keywords"
            action={<AddKeyword />}
          />
        </div>
        <Row
          gutter={24}
          style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
        >
          <Col span={24}>
            <Card>
              <div style={{ display: "flex" }}>
                <Search
                  placeholder="Search keywords..."
                  onSearch={value => {
                    this.setState({
                      searchValue: value,
                      keywordData: this.props.keywords.data.filter(
                        item =>
                          item.keyword.search(new RegExp(value, "i")) !== -1
                      )
                    });
                  }}
                  style={{ flex: 1 }}
                  enterButton
                />
                <span style={{ marginLeft: "20px" }}>
                  {"Sort by"}
                  <Dropdown overlay={this.dropdownMenu}>
                    <Button style={{ marginLeft: 8 }}>
                      {sort}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </span>
              </div>
            </Card>
          </Col>
        </Row>
        <Row
          gutter={24}
          style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
        >
          <Col span={24}>
            <Card>
              <Table
                columns={columns}
                dataSource={keywordData}
                showHeader={true}
                rowKey={record => record._id}
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ keywords }) => ({
  keywords
});

export default connect(
  mapStateToProps,
  { fetchKeywords, deleteKeyword }
)(Keywords);
