import { combineReducers } from "redux";
import authReducer from "./authReducers";
import { keywords } from "./keywordReducers";
import { plans, subscription, invoice } from "./billingReducers";
import {
  account,
  teamMembers,
  invite,
  facebookPages,
  authorizedSocialAccounts
} from "./accountReducers";
import {
  openMessages,
  closedMessages,
  myMessages,
  spamMessages,
  message,
  conversation
} from "./messagesReducers";
import scheduledPosts from "./scheduledPostsReducers";

export default combineReducers({
  auth: authReducer,
  keywords,
  plans,
  subscription,
  invoice,
  account,
  teamMembers,
  invite,
  facebookPages,
  authorizedSocialAccounts,
  openMessages,
  closedMessages,
  myMessages,
  spamMessages,
  message,
  conversation,
  scheduledPosts
});
