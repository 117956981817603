import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Icon, Spin, Dropdown } from "antd";
import isEmpty from "lodash.isempty";

import {
  RightEnd,
  CurrentUserDropdown,
  StyledAvatar,
  ToggleSiderMenuIcon
} from "./styles";

import { logoutUser } from "../../actions";

import { withRouter, Link } from "react-router-dom";

class GlobalHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.inputRef = React.createRef();
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
  };

  renderTrialExpiringBanner() {
    const { subscription, account } = this.props;
    if (isEmpty(subscription.data) && !subscription.loading) {
      let daysLeft = // account trial + ( negative number in days )
        account.data.max_trial_days +
        Math.ceil(
          (new Date(account.data.createdAt) - new Date()) / 1000 / 60 / 60 / 24
        );
      return (
        <h3>
          🔔 You have {daysLeft < 0 ? 0 : daysLeft} day(s) left in your
          trial.{" "}
          <Link to="/settings" style={{ color: "#1890ff" }}>
            Upgrade your plan
          </Link>
        </h3>
      );
    }
    return null;
  }

  renderLimitExceededBanner() {
    const { keywords, subscription, account } = this.props;

    if (
      !isEmpty(subscription.data) &&
      !subscription.loading &&
      keywords.data.length > account.data.max_tracked_words
    ) {
      let over = keywords.data.length - account.data.max_tracked_words;
      return (
        <h3>
          🔔 You have exceeded your plan limits. Please remove {over} keyword(s)
          below or{" "}
          <Link to="/settings" style={{ color: "#1890ff" }}>
            upgrade your plan
          </Link>
        </h3>
      );
    }
    return null;
  }
  render() {
    const { currentUser, collapsed, onMenuClick } = this.props;
    const menu = (
      <Menu selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item>
          <Link to="/settings">
            <Icon
              type="setting"
              style={{
                minWidth: "12px",
                marginRight: "8px"
              }}
            />
            Settings
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="logout"
          onClick={() =>
            this.props.logoutUser(() => this.props.history.push("/login"))
          }
        >
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <React.Fragment>
        <ToggleSiderMenuIcon
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={this.toggle}
        />
        {this.renderTrialExpiringBanner()}
        {this.renderLimitExceededBanner()}
        <RightEnd>
          {currentUser.name ? (
            <Dropdown overlay={menu}>
              <CurrentUserDropdown>
                <StyledAvatar size="small" src={currentUser.avatar} />
                <span>{currentUser.name}</span>
              </CurrentUserDropdown>
            </Dropdown>
          ) : (
            <Spin size="small" style={{ marginLeft: 8 }} />
          )}
        </RightEnd>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, subscription, plans, keywords, account }) => ({
  auth,
  subscription,
  plans,
  keywords,
  account
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { logoutUser }
  )
)(GlobalHeader);
