import React from "react";
import { Layout } from "antd";
import SiderMenu from "./SiderMenu";
const { Sider } = Layout;

const SiderMenuWrapper = ({
  trigger,
  collapsible,
  collapsed,
  menuKey,
  changeMenuKey,
  onCollapse
}) => {
  return (
    <Sider
      style={{
        overflow: "auto",
        minHeight: "100vh"
      }}
      collapsed={collapsed}
      collapsible={collapsible}
      onCollapse={onCollapse}
    >
      <SiderMenu
        trigger={trigger}
        collapsible={collapsible}
        collapsed={collapsed}
        menuKey={menuKey}
        changeMenuKey={changeMenuKey}
      />
    </Sider>
  );
};

export default SiderMenuWrapper;
