import React, { Component } from "react";
import { Row, Col, Card, Button, Icon, Table, Divider, Badge } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RedditSvg } from "../../assets/social/reddit";
import {
  fetchAccount,
  fetchTeamMembers,
  fetchFacebookPages,
  deleteTeamMemberSocialAccountAccess
} from "../../actions";

import "./social.css";
import AddTeamMemberToSocialAccount from "./AddTeamMemberToSocialAccount";

class SocialAccounts extends Component {
  componentDidMount() {
    this.props.fetchAccount();
    this.props.fetchTeamMembers();
    this.props.fetchFacebookPages();
  }

  expandedRowRender = record => {
    const columns = [
      { title: "Team Member", dataIndex: "email" },
      {
        title: "Action",
        key: "operation",
        render: row => (
          <Button
            type="danger"
            onClick={() => {
              this.props.deleteTeamMemberSocialAccountAccess(
                row.key,
                record._id,
                record.channel
              );
            }}
          >
            <Icon type="delete" />
            Remove
          </Button>
        )
      }
    ];

    const { teamMembers } = this.props;
    let data =
      record.channel === "reddit"
        ? teamMembers.data.reduce((acc, curr) => {
            let val = curr.reddit.find(({ _id }) => record._id === _id);
            if (val) {
              return [...acc, { ...curr, key: `${curr._id}${val._id}` }];
            } else return acc;
          }, [])
        : teamMembers.data.reduce((acc, curr) => {
            let val = curr.facebookPages.find(id => record._id === id);
            if (val) {
              return [...acc, { ...curr, key: `${curr._id}${val}` }];
            } else return acc;
          }, []);

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={record => record.key}
        loading={teamMembers.loading}
      />
    );
  };

  render() {
    const { auth, account, facebookPages } = this.props;

    if (account.loading || auth.loading) {
      return null;
    }

    const columns = [
      {
        title: "Channel",
        dataIndex: "channel",
        render: (text, record) =>
          record.channel === "reddit" ? (
            <div>
              <Icon component={RedditSvg} />
              <span style={{ marginLeft: "10px" }}>{record.displayName}</span>
            </div>
          ) : (
            <div>
              <Icon type="facebook" />
              <span style={{ marginLeft: "10px" }}>{record.name}</span>
            </div>
          )
      },
      {
        title: "Status",
        dataIndex: "status",
        className: "column-center",
        render: text => (
          <div style={{ textAlign: "center" }}>
            {text === "active" ? (
              <Badge status="success" />
            ) : (
              <Badge status="error" />
            )}
          </div>
        )
      },
      {
        title: "Action",
        key: "action",
        className: "column-center",
        render: record => (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <AddTeamMemberToSocialAccount record={record} />
            {/* <Divider type="vertical" />
            <Button type="danger" onClick={() => {}}>
              <Icon type="delete" />
              Remove
            </Button> */}
          </div>
        )
      }
    ];

    const redditAccounts = account.data.reddit.map(item => ({
      ...item,
      channel: "reddit",
      status: "active"
    }));
    const facebookPageAccounts = facebookPages.data.map(item => ({
      ...item,
      channel: "facebook",
      status: "active"
    }));

    const data = [...redditAccounts, ...facebookPageAccounts];

    return (
      <Col span={24}>
        <Card
          title={
            <div>
              {account.data.features.includes("REDDIT_INTEGRATION") ? (
                <Button
                  href={`${process.env.REACT_APP_API_BASE_URL}/auth/v1/reddit`}
                >
                  <Icon component={RedditSvg} />
                  Connect a Reddit Account
                </Button>
              ) : null}
              {account.data.features.includes("FACEBOOK_INTEGRATION") ? (
                <React.Fragment>
                  <Divider type="vertical" />
                  <Button
                    href={`${
                      process.env.REACT_APP_API_BASE_URL
                    }/auth/v1/facebook`}
                  >
                    <Icon type="facebook" />
                    Connect a Facebook Account
                  </Button>
                </React.Fragment>
              ) : null}
            </div>
          }
        >
          <Table
            columns={columns}
            dataSource={data}
            expandedRowRender={this.expandedRowRender}
            defaultExpandAllRows={true}
            rowKey={record => record._id}
          />
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = ({ auth, account, teamMembers, facebookPages }) => ({
  auth,
  account,
  teamMembers,
  facebookPages
});

export default connect(
  mapStateToProps,
  {
    fetchAccount,
    fetchTeamMembers,
    fetchFacebookPages,
    deleteTeamMemberSocialAccountAccess
  }
)(SocialAccounts);
