import React, { Component } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash.isempty";
import { Redirect } from "react-router";
import { Layout, Spin, Icon } from "antd";

import { fetchUser, fetchOpenMessages, fetchMyMessages } from "./actions";

import SiderMenu from "./components/SiderMenu/index";
import TopMenu from "./components/TopMenu";
import GlobalHeader from "./components/GlobalHeader";
import GlobalFooter from "ant-design-pro/lib/GlobalFooter";
import Keywords from "./components/Keywords";

import Settings from "./components/Settings";
import Payment from "./components/Settings/Billing/Payment";
import Plans from "./components/Settings/Billing/Plans";

import Integrations from "./components/Integrations";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Invitation from "./components/Auth/Invitation";
import CreateAccount from "./components/Auth/CreateAccount";

import NotFound from "./components/Error/NotFound";

// import Open from "./components/Open";
// import Mine from "./components/Mine";
// import Closed from "./components/Closed";
// import Spam from "./components/Spam";
// import Respond from "./components/Mine/Respond";

// import Scheduler from "./components/Scheduler";

const { Content, Header, Footer } = Layout;

const sideMenuKeys = {
  "/keywords": "1",
  "/integrations": "2",
  "/settings": "3",
  "/help": "4"
};

class App extends Component {
  state = {
    collapsed: false,
    sideMenuKey: []
  };

  componentDidMount() {
    const { auth, location } = this.props;
    let key = sideMenuKeys[location.pathname];

    if (
      location.pathname &&
      location.pathname !== "/login" &&
      location.pathname !== "/register" &&
      location.pathname !== "/account"
    ) {
      this.props.fetchUser();
      // this.props.fetchOpenMessages();
      // this.props.fetchMyMessages();
    }
    if (key) {
      this.setState({ sideMenuKey: [key] });
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  changeMenuKey = key => {
    return this.setState({ sideMenuKey: [key] });
  };

  renderLoading() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Spin size="large" />
        </div>
      </React.Fragment>
    );
  }

  renderMain() {
    const { auth } = this.props;
    const copyright = (
      <div>
        Copyright <Icon type="copyright" /> 2018 NotifiMe
      </div>
    );
    if (!isEmpty(auth.data)) {
      return (
        <Layout>
          {/* <TopMenu /> */}
          <Header
            style={{
              background: "#fff",
              padding: 0,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <GlobalHeader
              currentUser={{
                avatar:
                  "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png",
                name: auth.data._user.local.name
              }}
              collapsed={this.state.collapsed}
              onCollapse={this.toggle}
              onMenuClick={() => {}}
            />
          </Header>
          <Content style={{ margin: "24px 24px 0", height: "100%" }}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/keywords" />}
              />
              {/* <Route exact path="/respond/open" component={Open} />
              <Route exact path="/respond/mine" component={Mine} />
              <Route
                exact
                path="/respond/mine/conversations/:id"
                component={Respond}
              />
              <Route exact path="/respond/closed" component={Closed} />
              <Route exact path="/respond/spam" component={Spam} /> 
              <Route exact path="/respond/keywords" component={Keywords} />*/}
              <Route exact path="/keywords" component={Keywords} />
              <Route exact path="/integrations" component={Integrations} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/settings/payment" component={Payment} />
              <Route exact path="/settings/plans" component={Plans} />
              <Route exact path="/notfound" component={NotFound} />
              <Redirect to="/notfound" />
              {/* <Route exact path="/publish/scheduler" component={Scheduler} /> */}
            </Switch>
          </Content>
          <Footer style={{ padding: 0 }}>
            <div style={{ background: "#f5f5f5", overflow: "hidden" }}>
              <GlobalFooter copyright={copyright} />
            </div>
          </Footer>
        </Layout>
      );
    }
    return null;
  }

  render() {
    const { auth } = this.props;
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/account" component={CreateAccount} />
        <Route exact path="/invitation/:token" component={Invitation} />

        {auth.error && !auth.loading ? (
          <Redirect to="/login" />
        ) : auth.loading ? (
          this.renderLoading()
        ) : !auth.loading &&
          !isEmpty(auth.data) &&
          auth.data.message === "No associated memberships!" ? (
          <Redirect to="/account" />
        ) : (
          <React.Fragment>
            <Layout>
              <SiderMenu
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
                menuKey={this.state.sideMenuKey}
                changeMenuKey={this.changeMenuKey}
                onCollapse={this.toggle}
              />

              {this.renderMain()}
            </Layout>
          </React.Fragment>
        )}
      </Switch>
    );
  }
}

const mapStateToProps = ({ auth, subscription, account }) => ({
  auth,
  subscription,
  account
});
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { fetchUser, fetchOpenMessages, fetchMyMessages }
  )
)(App);
