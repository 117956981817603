import React, { Component } from "react";
import PageHeader from "ant-design-pro/lib/PageHeader";
import { connect } from "react-redux";
import { Row } from "antd";
import * as qs from "query-string";
import Billing from "./Billing/index.js";
import TeamMembers from "./TeamMembers";
import SocialAccounts from "./SocialAccounts";

class Settings extends Component {
  state = {
    tabActiveKey: "billing",
    tabList: [
      {
        key: "billing",
        tab: "Billing"
      },
      // {
      //   key: "team",
      //   tab: "Team Members"
      // }
      // { key: "social", tab: "Social Accounts" }
    ]
  };
  componentDidMount() {
    const { auth } = this.props;
    const parsed = qs.parse(this.props.location.search);
    console.log({ parsed });

    if (parsed.tab && this.state.tabList.find(i => i.key === parsed.tab)) {
      this.setState({ tabActiveKey: parsed.tab });
    }

    if (!["OWNER"].includes(auth.data.access)) {
      this.setState({
        tabActiveKey: "team",
        tabList: [
          {
            key: "team",
            tab: "Team Members"
          }
        ]
      });
    }
  }
  onTabChange = key => {
    this.setState({ tabActiveKey: key });
  };

  renderChild() {
    switch (this.state.tabActiveKey) {
      case "billing":
        return <Billing />;
      // case "team":
      //   return <TeamMembers />;
      // case "social":
      //   return <SocialAccounts />;
      default:
        return <Billing />;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ margin: "-24px -24px 0" }}>
          {/* <PageHeader key="pageheader" title="Settings" /> */}
          <PageHeader
            title="Settings"
            tabList={this.state.tabList}
            tabActiveKey={this.state.tabActiveKey}
            onTabChange={this.onTabChange}
          />
        </div>
        <React.Fragment>
          <Row gutter={24} style={{ marginTop: "24px" }}>
            {/* <Profile /> */}
            {this.renderChild()}
          </Row>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(
  mapStateToProps,
  {}
)(Settings);
