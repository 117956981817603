import axios from "axios";
import {
  FETCH_CURRENT_USER_STARTED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SIGNUP_USER_STARTED,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGOUT_USER,
  CLEAR_AUTH_STATE,
  FETCH_KEYWORDS_STARTED,
  FETCH_KEYWORDS_SUCCESS,
  FETCH_KEYWORDS_FAILURE,
  CREATE_KEYWORDS_STARTED,
  CREATE_KEYWORDS_FAILURE,
  DELETE_KEYWORDS_STARTED,
  DELETE_KEYWORDS_FAILURE,
  UPDATE_KEYWORDS_STARTED,
  UPDATE_KEYWORDS_FAILURE,
  FETCH_ACCOUNT_STARTED,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_STARTED,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  FETCH_TEAM_MEMBERS_STARTED,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  CREATE_INVITE_STARTED,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  FETCH_INVITE_STARTED,
  FETCH_INVITE_SUCCESS,
  FETCH_INVITE_FAILURE,
  CREATE_TEAM_MEMBER_STARTED,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_MEMBER_FAILURE,
  DELETE_TEAM_MEMBER_STARTED,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER_STARTED,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  FETCH_OPEN_MESSAGES_STARTED,
  FETCH_OPEN_MESSAGES_SUCCESS,
  FETCH_OPEN_MESSAGES_FAILURE,
  FETCH_CLOSED_MESSAGES_STARTED,
  FETCH_CLOSED_MESSAGES_SUCCESS,
  FETCH_CLOSED_MESSAGES_FAILURE,
  FETCH_MY_MESSAGES_STARTED,
  FETCH_MY_MESSAGES_SUCCESS,
  FETCH_MY_MESSAGES_FAILURE,
  FETCH_SPAM_MESSAGES_STARTED,
  FETCH_SPAM_MESSAGES_SUCCESS,
  FETCH_SPAM_MESSAGES_FAILURE,
  UPDATE_MESSAGE_STARTED,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  FETCH_CONVERSATION_STARTED,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  CREATE_REDDIT_CONVERSATION_REPLY_STARTED,
  CREATE_REDDIT_CONVERSATION_REPLY_SUCCESS,
  CREATE_REDDIT_CONVERSATION_REPLY_FAILURE,
  UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_STARTED,
  UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_SUCCESS,
  UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_FAILURE,
  DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_STARTED,
  DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_SUCCESS,
  DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_FAILURE,
  FETCH_MORE_REDDIT_COMMENTS_STARTED,
  FETCH_MORE_REDDIT_COMMENTS_SUCCESS,
  FETCH_MORE_REDDIT_COMMENTS_FAILURE,
  FETCH_FACEBOOK_PAGES_STARTED,
  FETCH_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGES_FAILURE,
  FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_STARTED,
  FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_SUCCESS,
  FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_FAILURE,
  DELETE_SLACK_INTEGRATION_STARTED,
  DELETE_SLACK_INTEGRATION_FAILURE
} from "./types";
import { fetchSubscription } from "./billing.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export * from "./billing.js";

export const fetchUser = () => async dispatch => {
  try {
    dispatch({ type: FETCH_CURRENT_USER_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/current_user`, {
      withCredentials: true
    });
    dispatch(fetchSubscription());
    // dispatch(fetchPlans());
    dispatch(fetchAccount());
    dispatch({ type: FETCH_CURRENT_USER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: FETCH_CURRENT_USER_FAILURE,
      payload: error.response.data
    });
  }
};

export const loginUser = (email, password) => async dispatch => {
  try {
    dispatch({ type: LOGIN_USER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/auth/v1/login`,
      {
        email,
        password
      },
      { withCredentials: true }
    );
    dispatch({ type: LOGIN_USER_SUCCESS, payload: res.data });
    if (!res.data.message) {
      dispatch(fetchUser());
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: LOGIN_USER_FAILURE, payload: error.response.data });
  }
};

export const signUpUser = (
  email,
  password,
  name,
  companyName
) => async dispatch => {
  try {
    dispatch({ type: SIGNUP_USER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/auth/v1/signup`,
      {
        email,
        password,
        name,
        companyName
      },
      { withCredentials: true }
    );
    dispatch({
      type: SIGNUP_USER_SUCCESS,
      payload: res.data
    });
    dispatch(fetchUser());
  } catch (error) {
    dispatch({ type: SIGNUP_USER_FAILURE, payload: error.response.data });
  }
};

export const logoutUser = callback => async dispatch => {
  const res = await axios.get(`${API_BASE_URL}/auth/v1/logout`, {
    withCredentials: true
  });
  dispatch({ type: LOGOUT_USER, payload: res.data });
  callback();
};

export const clearAuthState = () => dispatch => {
  dispatch({ type: CLEAR_AUTH_STATE, payload: {} });
};

export const fetchKeywords = () => async dispatch => {
  try {
    dispatch({ type: FETCH_KEYWORDS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/keywords`, {
      withCredentials: true
    });
    let { keywords } = res.data;
    dispatch({ type: FETCH_KEYWORDS_SUCCESS, payload: keywords });
  } catch (error) {
    dispatch({ type: FETCH_KEYWORDS_FAILURE, payload: error.response.data });
  }
};

export const createKeyword = (keyword, wholeWordOnly) => async dispatch => {
  try {
    dispatch({ type: CREATE_KEYWORDS_STARTED });
    await axios.post(
      `${API_BASE_URL}/api/v1/keywords`,
      {
        keyword,
        wholeWordOnly
      },
      { withCredentials: true }
    );
    dispatch(fetchKeywords());
  } catch (error) {
    dispatch({ type: CREATE_KEYWORDS_FAILURE, payload: error.response.data });
  }
};

export const deleteKeyword = id => async dispatch => {
  try {
    dispatch({ type: DELETE_KEYWORDS_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/keywords`, {
      data: {
        id
      },
      withCredentials: true
    });
    dispatch(fetchKeywords());
  } catch (error) {
    dispatch({ type: DELETE_KEYWORDS_FAILURE, payload: error });
  }
};

export const updateKeyword = (id, keyword, wholeWordOnly) => async dispatch => {
  try {
    dispatch({ type: UPDATE_KEYWORDS_STARTED });
    await axios.put(
      `${API_BASE_URL}/api/v1/keywords`,
      {
        id,
        keyword,
        wholeWordOnly
      },
      { withCredentials: true }
    );
    dispatch(fetchKeywords());
  } catch (error) {
    dispatch({ type: UPDATE_KEYWORDS_FAILURE, payload: error.response.data });
  }
};

export const fetchAccount = () => async dispatch => {
  try {
    dispatch({ type: FETCH_ACCOUNT_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/account`, {
      withCredentials: true
    });
    let { account } = res.data;
    dispatch({ type: FETCH_ACCOUNT_SUCCESS, payload: account });
  } catch (error) {
    dispatch({ type: FETCH_ACCOUNT_FAILURE, payload: error.response.data });
  }
};

export const deleteSlackIntegration = () => async dispatch => {
  try {
    dispatch({ type: DELETE_SLACK_INTEGRATION_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/integrations/slack`, {
      data: {},
      withCredentials: true
    });
    dispatch(fetchAccount());
  } catch (error) {
    dispatch({ type: DELETE_SLACK_INTEGRATION_FAILURE, payload: error });
  }
};

export const fetchTeamMembers = () => async dispatch => {
  try {
    dispatch({ type: FETCH_TEAM_MEMBERS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/account/team`, {
      withCredentials: true
    });
    let { members } = res.data;
    dispatch({ type: FETCH_TEAM_MEMBERS_SUCCESS, payload: members });
  } catch (error) {
    dispatch({
      type: FETCH_TEAM_MEMBERS_FAILURE,
      payload: error.response.data
    });
  }
};

export const createInvite = (email, accessRole) => async dispatch => {
  try {
    dispatch({ type: CREATE_INVITE_STARTED });
    await axios.post(
      `${API_BASE_URL}/api/v1/account/team/invite`,
      {
        email,
        accessRole
      },
      { withCredentials: true }
    );
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({ type: CREATE_INVITE_FAILURE, payload: error.response.data });
  }
};

export const fetchInvite = token => async dispatch => {
  try {
    dispatch({ type: FETCH_INVITE_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/account/invite/${token}`,
      {
        withCredentials: true
      }
    );
    let { invite } = res.data;
    dispatch({ type: FETCH_INVITE_SUCCESS, payload: invite });
  } catch (error) {
    dispatch({
      type: FETCH_INVITE_FAILURE,
      payload: error.response.data
    });
  }
};

export const createTeamMember = (
  _invite,
  email,
  password,
  name,
  _account,
  callback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_TEAM_MEMBER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/api/v1/account/team/user`,
      {
        _invite,
        email,
        password,
        name,
        _account
      },
      { withCredentials: true }
    );
    dispatch({ type: CREATE_TEAM_MEMBER_SUCCESS, payload: res.data });
    callback();
  } catch (error) {
    dispatch({
      type: CREATE_TEAM_MEMBER_FAILURE,
      payload: error.response.data
    });
  }
};

export const deleteTeamMember = (id, type) => async dispatch => {
  try {
    dispatch({ type: DELETE_TEAM_MEMBER_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/account/team/user`, {
      data: {
        id,
        type
      },
      withCredentials: true
    });
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({ type: DELETE_TEAM_MEMBER_FAILURE, payload: error });
  }
};

export const updateTeamMember = (id, type, access) => async dispatch => {
  try {
    dispatch({ type: UPDATE_TEAM_MEMBER_STARTED });
    await axios.patch(
      `${API_BASE_URL}/api/v1/account/team/user`,
      {
        id,
        type,
        access
      },
      { withCredentials: true }
    );
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({
      type: UPDATE_TEAM_MEMBER_FAILURE,
      payload: error.response.data
    });
  }
};

export const createAccount = (
  _user,
  companyName,
  callback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_ACCOUNT_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/api/v1/account/user`,
      {
        _user,
        companyName
      },
      { withCredentials: true }
    );
    let { account } = res.data;
    dispatch(clearAuthState());
    callback();
    dispatch({ type: CREATE_ACCOUNT_SUCCESS, payload: account });
  } catch (error) {
    dispatch({ type: CREATE_ACCOUNT_FAILURE, payload: error.response.data });
  }
};

export const fetchOpenMessages = () => async dispatch => {
  try {
    dispatch({ type: FETCH_OPEN_MESSAGES_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/messages/open`, {
      withCredentials: true
    });
    let { messages } = res.data;
    dispatch({ type: FETCH_OPEN_MESSAGES_SUCCESS, payload: messages });
  } catch (error) {
    dispatch({
      type: FETCH_OPEN_MESSAGES_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchClosedMessages = () => async dispatch => {
  try {
    dispatch({ type: FETCH_CLOSED_MESSAGES_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/messages/closed`, {
      withCredentials: true
    });
    let { messages } = res.data;
    dispatch({ type: FETCH_CLOSED_MESSAGES_SUCCESS, payload: messages });
  } catch (error) {
    dispatch({
      type: FETCH_CLOSED_MESSAGES_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchMyMessages = () => async dispatch => {
  try {
    dispatch({ type: FETCH_MY_MESSAGES_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/messages/mine`, {
      withCredentials: true
    });
    let { messages } = res.data;
    dispatch({ type: FETCH_MY_MESSAGES_SUCCESS, payload: messages });
  } catch (error) {
    dispatch({ type: FETCH_MY_MESSAGES_FAILURE, payload: error.response.data });
  }
};

export const fetchSpamMessages = () => async dispatch => {
  try {
    dispatch({ type: FETCH_SPAM_MESSAGES_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/messages/spam`, {
      withCredentials: true
    });
    let { messages } = res.data;
    dispatch({ type: FETCH_SPAM_MESSAGES_SUCCESS, payload: messages });
  } catch (error) {
    dispatch({
      type: FETCH_SPAM_MESSAGES_FAILURE,
      payload: error.response.data
    });
  }
};

export const updateMessage = (
  messageIds,
  action,
  memberId = null,
  callback = () => {}
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_MESSAGE_STARTED });
    await axios.patch(
      `${API_BASE_URL}/api/v1/messages`,
      {
        messageIds,
        memberId,
        action
      },
      { withCredentials: true }
    );
    // dispatch(fetchOpenMessages());
    // dispatch(fetchMyMessages());
    callback();
    dispatch({ type: UPDATE_MESSAGE_SUCCESS, payload: {} });
  } catch (error) {
    dispatch({
      type: UPDATE_MESSAGE_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchConversation = messageId => async dispatch => {
  try {
    dispatch({ type: FETCH_CONVERSATION_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/messages/conversation/${messageId}`,
      {
        withCredentials: true
      }
    );
    let { conversation, channel } = res.data;
    dispatch({
      type: FETCH_CONVERSATION_SUCCESS,
      payload: conversation,
      channel
    });
  } catch (error) {
    dispatch({
      type: FETCH_CONVERSATION_FAILURE,
      payload: error.response.data
    });
  }
};

export const createRedditConversationReply = (
  text,
  id,
  type,
  callback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_REDDIT_CONVERSATION_REPLY_STARTED });
    await axios.post(
      `${API_BASE_URL}/api/v1/integrations/reddit/reply`,
      {
        text,
        id,
        type
      },
      { withCredentials: true }
    );
    callback();
  } catch (error) {
    dispatch({
      type: CREATE_REDDIT_CONVERSATION_REPLY_FAILURE,
      payload: error.response.data
    });
  }
};

export const updateTeamMemberSocialAccountAccess = (
  memberId,
  socialAccountId,
  channel
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_STARTED });
    await axios.patch(
      `${API_BASE_URL}/api/v1/integrations/team/user`,
      {
        memberId,
        socialAccountId,
        channel
      },
      { withCredentials: true }
    );
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({
      type: UPDATE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_FAILURE,
      payload: error.response.data
    });
  }
};

export const deleteTeamMemberSocialAccountAccess = (
  memberId,
  socialAccountId,
  channel
) => async dispatch => {
  try {
    dispatch({ type: DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/integrations/team/user`, {
      data: {
        memberId,
        socialAccountId,
        channel
      },
      withCredentials: true
    });
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({
      type: DELETE_TEAM_MEMBER_SOCIAL_ACCOUNT_ACCESS_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchAuthorizedSocialAccounts = () => async dispatch => {
  try {
    dispatch({ type: FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/account/social`, {
      withCredentials: true
    });
    let { socialAccounts } = res.data;
    dispatch({
      type: FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_SUCCESS,
      payload: socialAccounts
    });
  } catch (error) {
    dispatch({
      type: FETCH_AUTHORIZED_SOCIAL_ACCOUNTS_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchFacebookPages = () => async dispatch => {
  try {
    dispatch({ type: FETCH_FACEBOOK_PAGES_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/account/social/facebookpages`,
      {
        withCredentials: true
      }
    );
    let { facebookPages } = res.data;
    dispatch({ type: FETCH_FACEBOOK_PAGES_SUCCESS, payload: facebookPages });
  } catch (error) {
    dispatch({
      type: FETCH_FACEBOOK_PAGES_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchMoreRedditComments = (
  linkId,
  children,
  moreId,
  parentName,
  initial
) => async dispatch => {
  try {
    dispatch({ type: FETCH_MORE_REDDIT_COMMENTS_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/messages/conversation/more?linkId=${linkId}&children=${children.join(
        ","
      )}&moreId=${moreId}&parentName=${parentName}`,
      {
        withCredentials: true
      }
    );
    let { comments, channel } = res.data;

    let conversation = mergeComments(initial, moreId, comments);
    console.log(
      { conversation },
      JSON.stringify(conversation) === JSON.stringify(initial)
    );
    dispatch({
      type: FETCH_MORE_REDDIT_COMMENTS_SUCCESS,
      payload: conversation,
      channel
    });
  } catch (error) {
    dispatch({
      type: FETCH_MORE_REDDIT_COMMENTS_FAILURE,
      payload: error.response.data
    });
  }
};

export * from "./scheduledPosts.js";

const mergeComments = (initial, moreId, comments) => {
  const merge = replies => {
    if (!replies) {
      return;
    } else if (
      replies.kind === "Listing" &&
      replies.data &&
      replies.data.children &&
      replies.data.children.filter(
        item => item.kind === "more" && item.data && item.data.id === moreId
      ).length > 0
    ) {
      let arr = JSON.parse(JSON.stringify(replies.data.children));
      let newArr = arr.filter(
        item => !(item.kind === "more" && item.data && item.data.id === moreId)
      );
      replies.data.children = [...newArr, ...comments];
    } else if (
      (replies.kind === "t1" || replies.kind === "Listing") &&
      replies.data &&
      replies.data.children
    ) {
      replies.data.children.forEach(item => {
        merge(item.data.replies);
      });
    }
  };
  let state = JSON.parse(JSON.stringify(initial));
  merge(state.comments);
  return state;
};
