import React from "react";
import { Button, Modal, Form, Input, Select } from "antd";
import { connect } from "react-redux";

import { updateTeamMemberSocialAccountAccess } from "../../actions";
const Option = Select.Option;

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        teamMembers,
        record
      } = this.props;
      const { getFieldDecorator } = form;
      let data = teamMembers.data.reduce((acc, curr) => {
        let val = curr.reddit.find(({ _id }) => record._id === _id);
        if (val) {
          return acc;
        } else return [...acc, curr];
      }, []);
      return (
        <Modal
          visible={visible}
          title="Add a team member"
          okText="Add"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Select Team Member" hasFeedback>
              {getFieldDecorator("memberId", {
                rules: [
                  { required: true, message: "Please select a team member!" }
                ]
              })(
                <Select placeholder="Team Member">
                  {data.map(item => (
                    <Option key={item.key} value={item.key}>
                      {item.email}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class AddTeamMemberToSocialAccount extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { record } = this.props;

    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

    //   console.log("Received values of form: ", values);
      this.props.updateTeamMemberSocialAccountAccess(
        values.memberId,
        record._id,
        record.channel
      )
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { teamMembers, record } = this.props;
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Add Team Member
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          teamMembers={teamMembers}
          record={record}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ teamMembers }) => ({
  teamMembers
});

export default connect(
  mapStateToProps,
  { updateTeamMemberSocialAccountAccess }
)(AddTeamMemberToSocialAccount);
