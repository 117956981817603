import {
  FETCH_SCHEDULED_POSTS_STARTED,
  FETCH_SCHEDULED_POSTS_SUCCESS,
  FETCH_SCHEDULED_POSTS_FAILURE,
  CREATE_SCHEDULED_POSTS_STARTED,
  CREATE_SCHEDULED_POSTS_FAILURE,
  DELETE_SCHEDULED_POSTS_STARTED,
  DELETE_SCHEDULED_POSTS_FAILURE,
  UPDATE_SCHEDULED_POSTS_STARTED,
  UPDATE_SCHEDULED_POSTS_FAILURE
} from "../actions/types";

const initialState = {
  loading: false,
  data: [],
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULED_POSTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_SCHEDULED_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_SCHEDULED_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_SCHEDULED_POSTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_SCHEDULED_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_SCHEDULED_POSTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_SCHEDULED_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_SCHEDULED_POSTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SCHEDULED_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
