import React from "react";
import { Menu, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";

const keys = {
  "/publish": "1",
  "/respond": "2",
  "/listen": "3",
  "/analytics": "4"
};

const regex = path => {
  return Object.keys(keys).reduce((acc, curr) => {
    let re = new RegExp(curr, "g");
    let exec = re.exec(path);
    if (exec) {
      return exec;
    }
    return acc;
  }, null);
};

class TopMenu extends React.Component {
  _isMounted = false;

  state = {
    selectedKeys: []
  };
  componentDidMount() {
    this._isMounted = true;
    let arr = regex(this.props.location.pathname);
    if (arr) {
      this.setState({ selectedKeys: [keys[arr[0]]] });
    } else {
      this.setState({ selectedKeys: [] });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let arr = regex(this.props.location.pathname);
      if (arr) {
        this.setState({ selectedKeys: [keys[arr[0]]] });
      } else {
        this.setState({ selectedKeys: [] });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this._isMounted) {
      return null;
    } else
      return (
        <Menu
          mode="horizontal"
          theme="dark"
          selectedKeys={this.state.selectedKeys}
          style={{ padding: "2vh" }}
        >
          <Menu.Item key="1" className="top-menu">
            <Link to="/publish">
              <Icon type="file" />
              <span>Publish</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2" className="top-menu">
            <Link to="/respond/open">
              <Icon type="message" />
              <span>Respond</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3" className="top-menu">
            <Link to="/listen">
              <Icon type="filter" />
              <span>Listen</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="4" className="top-menu">
            <Link to="/analytics">
              <Icon type="sliders" />
              <span>Analytics</span>
            </Link>
          </Menu.Item>
        </Menu>
      );
  }
}

export default withRouter(TopMenu);
