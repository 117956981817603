import React from "react";
import { Button, Modal, Form, Input, Checkbox } from "antd";
import { connect } from "react-redux";

import { updateKeyword } from "../../actions";

const FormItem = Form.Item;

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, record } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Edit Keyword"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <FormItem label="Keyword">
              {getFieldDecorator("keyword", {
                initialValue: record.keyword,
                rules: [
                  {
                    required: true,
                    message: "Please input a keyword!"
                  },
                  {
                    validator: (rule, value, cb) =>
                      value.toLowerCase() === value ? cb() : cb(true),
                    message:
                      "Matches are not case-sensitive. Please use lowercase letters."
                  }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="Match whole word only?">
              {getFieldDecorator("wholeWordOnly", {
                initialValue: record.wholeWordOnly,
                valuePropName: 'checked',
              })(<Checkbox />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

class EditKeyword extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.updateKeyword(
        this.props.record._id,
        values.keyword,
        values.wholeWordOnly
      );
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.showModal}>Edit</Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          record={this.props.record}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { updateKeyword }
)(EditKeyword);
