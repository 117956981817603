import React, { Component } from "react";
import { Menu, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import logo from "./assets/icon.svg";

const SubMenu = Menu.SubMenu;

const keys = {
  "/respond/open": "1",
  "/respond/mine": "2",
  "/respond/closed": "3",
  "/respond/spam": "4",
  "/respond/keywords": "5",
  "/integrations": "6",
  "/settings": "7",
  "/questions": "8",
  "/publish/scheduler": "9"
};

const topMenuKeys = ["/publish", "/respond", "/listen", "/analytics"];


const SiderMenu = props => {
  const { collapsed, menuKey, changeMenuKey } = props;

  const styles = {
    logo: {
      color: "white",
      fontSize: "30px",
      verticalAlign: "middle"
    },
    h1: {
      color: "white",
      display: "inline-block",
      verticalAlign: "middle",
      fontSize: "20px",
      margin: "0 0 0 14px",
      fontFamily:
        "'Myriad PFro', 'Helvetica Neue', Arial, Helvetica, sans-serif",
      fontWeight: "600"
    }
  };
  return (
    <React.Fragment>
      <Menu
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        selectedKeys={menuKey}
        onClick={({key}) => changeMenuKey(key)}
      >
        <Menu.Item
          title={"NotifiMe"}
          key="index"
          style={{
            marginBottom: "24px",
            marginTop: "24px",
            height: "100%",
            backgroundColor: "#001529"
          }}
        >
          <img
            src={logo}
            alt="NotifiMe Logo"
            style={{
              height: "50px",
              width: "50px",
              minWidth: "50px",
              marginLeft: "-14px"
            }}
          />
          {collapsed ? null : <span style={styles.h1}>NotifiMe</span>}
        </Menu.Item>
        <Menu.Item key="1" title="Keywords">
          <Link to="/keywords">
            <Icon type="eye" />
            <span>Keywords</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2" title="Integrations">
          <Link to="/integrations">
            <Icon type="branches" />
            <span>Integrations</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3" title="Settings">
          <Link to="/settings">
            <Icon type="setting" />
            <span>Settings</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4" title="Help">
          <a href="mailto:support@notifime.app">
            <Icon type="question" />
            <span>Help</span>
          </a>
        </Menu.Item>
      </Menu>
    </React.Fragment>
  );
};

export default withRouter(SiderMenu);
