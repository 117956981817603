import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Table,
  Divider,
  Popover,
  Popconfirm,
  Select
} from "antd";
import { connect } from "react-redux";
import {
  fetchTeamMembers,
  createInvite,
  deleteTeamMember,
  updateTeamMember
} from "../../actions";
import { Link } from "react-router-dom";
import EditTeamMember from "./EditTeamMember";

const Option = Select.Option;

const text = (
  <span>
    <Link to="/settings" style={{ color: "#1890ff" }}>
      Upgrade your plan
    </Link>
  </span>
);
const content = (
  <div>
    <p>You have maxed out the number of team members in your account.</p>
  </div>
);

const ExceededLimits = () => (
  <Popover placement="bottom" title={text} content={content} trigger="click">
    <Button style={{ marginLeft: "1rem" }} type="primary">
      Add
    </Button>
  </Popover>
);

class TeamMembers extends Component {
  state = {
    email: "",
    accessRole: "",
    editing: []
  };

  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  addTeamMember = () => {
    let { email, accessRole } = this.state;
    if (email && accessRole) {
      this.props.createInvite(email, accessRole);
    }
  };

  handleSelect = value => {
    this.setState({ accessRole: value });
  };

  handleInput = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { teamMembers, account, auth } = this.props;
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Access",
        dataIndex: "access",
        key: "access"
      },
      {
        title: "",
        key: "actions",
        render: (text, record) => (
          <div>
            {!["OWNER", "ADMIN"].includes(auth.data.access) ? null : (
              <React.Fragment>
                {record.access === "OWNER" ? null : (
                  <React.Fragment>
                    <span>{record.status}</span>
                    <Divider type="vertical" />
                  </React.Fragment>
                )}
                {record.status === "active" ||
                record.access === "OWNER" ? null : (
                  <React.Fragment>
                    <Button>Resend Invitation</Button>
                    <Divider type="vertical" />
                  </React.Fragment>
                )}

                {record.access === "OWNER" ? null : (
                  <React.Fragment>
                    <EditTeamMember record={record} />
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => {
                        if (record.status === "active") {
                          return this.props.deleteTeamMember(
                            record.key,
                            "membership"
                          );
                        }
                        return this.props.deleteTeamMember(
                          record.key,
                          "invite"
                        );
                      }}
                    >
                      <Button icon="delete" type="danger">
                        Delete
                      </Button>
                    </Popconfirm>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )
      }
    ];

    if (!teamMembers.loading && !account.loading && !auth.loading) {
      return (
        <React.Fragment>
          {!["OWNER", "ADMIN"].includes(auth.data.access) ? null : (
            <Row gutter={24} style={{ marginTop: "24px" }}>
              <Col span={24}>
                <Card title="Add new team member">
                  <Input
                    placeholder="Email"
                    style={{ width: "15vw", marginLeft: "1rem" }}
                    onChange={this.handleInput}
                    value={this.state.email}
                  />
                  <Select
                    placeholder="Access Role"
                    style={{ width: 120, marginLeft: "1rem" }}
                    onChange={this.handleSelect}
                  >
                    <Option value="ADMIN">Admin</Option>
                    <Option value="MEMBER">Member</Option>
                  </Select>
                  {teamMembers.data.length < account.data.max_team_size ? (
                    <Button
                      type="primary"
                      style={{ marginLeft: "1rem" }}
                      onClick={this.addTeamMember}
                    >
                      Add
                    </Button>
                  ) : (
                    <ExceededLimits />
                  )}
                </Card>
              </Col>
            </Row>
          )}

          <Row gutter={24} style={{ marginTop: "24px" }}>
            <Col span={24}>
              <Card title="My team">
                <Table columns={columns} dataSource={teamMembers.data} />
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else return null;
  }
}
const mapStateToProps = ({ auth, teamMembers, account, invite }) => ({
  auth,
  teamMembers,
  account,
  invite
});

export default connect(
  mapStateToProps,
  { fetchTeamMembers, createInvite, deleteTeamMember, updateTeamMember }
)(TeamMembers);
