import {
  FETCH_OPEN_MESSAGES_STARTED,
  FETCH_OPEN_MESSAGES_SUCCESS,
  FETCH_OPEN_MESSAGES_FAILURE,
  FETCH_CLOSED_MESSAGES_STARTED,
  FETCH_CLOSED_MESSAGES_SUCCESS,
  FETCH_CLOSED_MESSAGES_FAILURE,
  FETCH_MY_MESSAGES_STARTED,
  FETCH_MY_MESSAGES_SUCCESS,
  FETCH_MY_MESSAGES_FAILURE,
  FETCH_SPAM_MESSAGES_STARTED,
  FETCH_SPAM_MESSAGES_SUCCESS,
  FETCH_SPAM_MESSAGES_FAILURE,
  UPDATE_MESSAGE_STARTED,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  FETCH_CONVERSATION_STARTED,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  FETCH_MORE_REDDIT_COMMENTS_STARTED,
  FETCH_MORE_REDDIT_COMMENTS_SUCCESS,
  FETCH_MORE_REDDIT_COMMENTS_FAILURE,
  CREATE_REDDIT_CONVERSATION_REPLY_STARTED,
  CREATE_REDDIT_CONVERSATION_REPLY_SUCCESS,
  CREATE_REDDIT_CONVERSATION_REPLY_FAILURE
} from "../actions/types";

const initialOpenMessagesState = {
  loading: false,
  data: [],
  error: null
};

export const openMessages = (state = initialOpenMessagesState, action) => {
  switch (action.type) {
    case FETCH_OPEN_MESSAGES_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_OPEN_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_OPEN_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialClosedMessagesState = {
  loading: false,
  data: [],
  error: null
};

export const closedMessages = (state = initialClosedMessagesState, action) => {
  switch (action.type) {
    case FETCH_CLOSED_MESSAGES_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_CLOSED_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_CLOSED_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialMyMessagesState = {
  loading: false,
  data: [],
  error: null
};

export const myMessages = (state = initialMyMessagesState, action) => {
  switch (action.type) {
    case FETCH_MY_MESSAGES_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_MY_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_MY_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialSpamMessagesState = {
  loading: false,
  data: [],
  error: null
};

export const spamMessages = (state = initialSpamMessagesState, action) => {
  switch (action.type) {
    case FETCH_SPAM_MESSAGES_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_SPAM_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_SPAM_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialMessageState = {
  loading: false,
  data: {},
  error: null
};

export const message = (state = initialMessageState, action) => {
  switch (action.type) {
    case UPDATE_MESSAGE_STARTED:
      return {
        ...state,
        loading: true
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialConversationState = {
  loading: false,
  data: {},
  channel: null,
  error: null
};

export const conversation = (state = initialConversationState, action) => {
  switch (action.type) {
    case FETCH_CONVERSATION_STARTED:
      return {
        ...state,
        data: {},
        channel: null,
        loading: true
      };
    case FETCH_CONVERSATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        channel: action.channel
      };
    case FETCH_CONVERSATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_MORE_REDDIT_COMMENTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_MORE_REDDIT_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        channel: action.channel
      };
    case FETCH_MORE_REDDIT_COMMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_REDDIT_CONVERSATION_REPLY_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_REDDIT_CONVERSATION_REPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        channel: action.channel
      };
    case CREATE_REDDIT_CONVERSATION_REPLY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
