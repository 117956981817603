import React from "react";
import { Popover, Button, Modal, Form, Input, Checkbox } from "antd";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";

import { createKeyword } from "../../actions";
import { combineReducers } from "redux";
import Link from "react-router-dom/Link";

const FormItem = Form.Item;

const text = (
  <span>
    <Link to="/settings" style={{ color: "#1890ff" }}>
      Upgrade your plan
    </Link>
  </span>
);
const content = (
  <div>
    <p>You have maxed out the number of monitored keywords in your account.</p>
  </div>
);

const ExceededLimits = () => (
  <Popover placement="bottom" title={text} content={content} trigger="click">
    <Button type="primary">Add New Keyword</Button>
  </Popover>
);

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Add a New Keyword"
          okText="Add"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <FormItem label="Keyword">
              {getFieldDecorator("keyword", {
                rules: [
                  {
                    required: true,
                    message: "Please input a keyword!"
                  },
                  {
                    validator: (rule, value, cb) =>
                      value.toLowerCase() === value ? cb() : cb(true),
                    message:
                      "Matches are not case-sensitive. Please use lowercase letters."
                  }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="Match whole word only?">
              {getFieldDecorator("wholeWordOnly", {
                initialValue: false
              })(<Checkbox />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

class AddKeyword extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      console.log(values);
      this.props.createKeyword(values.keyword, values.wholeWordOnly);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderButtonToModal() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Add New Keyword
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
  render() {
    const { keywords, subscription, auth, account } = this.props;
    let daysLeft = // account trial days + ( negative number in days )
      account.data.max_trial_days +
      Math.ceil(
        (new Date(account.data.createdAt) - new Date()) / 1000 / 60 / 60 / 24
      );
    if (isEmpty(subscription.data)) {
      // no plan
      if (
        daysLeft >= 0 &&
        keywords.data.length < account.data.max_tracked_words
      ) {
        // still in trial AND keywords less than max plan
        return this.renderButtonToModal();
      } else {
        return <ExceededLimits />;
      }
    } else if (
      !isEmpty(subscription.data) &&
      keywords.data.length >= account.data.max_tracked_words
    ) {
      return <ExceededLimits />;
    } else {
      return this.renderButtonToModal();
    }
  }
}

const mapStateToProps = ({ keywords, subscription, auth, account }) => ({
  keywords,
  subscription,
  auth,
  account
});

export default connect(
  mapStateToProps,
  { createKeyword }
)(AddKeyword);
