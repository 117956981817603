import React from "react";
import { Row, Col, Table, Card, Button } from "antd";
import { connect } from "react-redux";
import PageHeader from "ant-design-pro/lib/PageHeader";

import { fetchAccount, deleteSlackIntegration } from "../../actions";

import slackLogo from "./assets/slack.svg";

class Integrations extends React.Component {
  componentDidMount() {
    this.props.fetchAccount();
  }
  renderButton(name) {
    switch (name) {
      case "Slack":
        const {
          account: { data }
        } = this.props;
        if (data.slack && data.slack.url && data.slack.channel) {
          return (
            <React.Fragment>
              <div
                style={{
                  marginRight: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                {data.slack.channel}
              </div>
              <Button
                type="primary"
                style={{
                  marginRight: "20px",
                  background: "#4e18ff",
                  borderColor: "#4e18ff"
                }}
                href={process.env.REACT_APP_SLACK_SHARABLE_URL}
              >
                Enabled
              </Button>
              <Button type="danger" onClick={this.props.deleteSlackIntegration}>
                Remove
              </Button>
            </React.Fragment>
          );
        } else {
          return (
            <Button
              type="primary"
              href={process.env.REACT_APP_SLACK_SHARABLE_URL}
            >
              Enable
            </Button>
          );
        }
      default:
        return null;
    }
  }
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <div style={{ display: "flex" }}>
            <img
              src={record.logo}
              alt={record.alt}
              style={{ maxHeight: "50px", maxWidth: "50px" }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px"
              }}
            >
              <h3>{record.name}</h3>
            </div>
          </div>
        )
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.renderButton(record.name)}
          </div>
        )
      }
    ];

    const data = [
      {
        key: "1",
        name: "Slack",
        logo: slackLogo,
        alt: "slack logo"
      }
    ];
    return (
      <React.Fragment>
        <div style={{ margin: "-24px -24px 0" }}>
          <PageHeader key="pageheader" title="Integrations" />
        </div>
        <Row
          gutter={24}
          style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
        >
          <Col span={24}>
            <Card>
              <Table
                columns={columns}
                dataSource={data}
                showHeader={false}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ account }) => ({
  account
});

export default connect(
  mapStateToProps,
  { fetchAccount, deleteSlackIntegration }
)(Integrations);
